import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UserRoleFilter } from '@cosman/authorize';
import { MasterFramework } from '@cosman/master-framework';
import { withDialogContext } from '@cosman/shared-components';
import { AccessControlContext, withAccessControlContext, withFavoriteContext, withPreferenceContext } from '@cosman/contexts';
import { withDefaultStyles } from '@cosman/functions';
import { useTracker } from '@cosman/hooks';
import { routes, menus, actions } from './index';
import { getStyles } from './App.styles';

const AppPure = (props) => {
  const { classes } = props;
  const { createAccessControlService } = useContext(AccessControlContext);

  const accessControl = useMemo(() => {
    return createAccessControlService();
  }, [createAccessControlService]);

  const options = useMemo(() => ({
    roles: accessControl && accessControl.roles,
  }), [accessControl]);

  const handleFilter = useCallback((...roles) => {
    return accessControl.hasRole(...roles);
  }, [accessControl]);

  const { trackPageView, trackEnd2EndLatency } = useTracker();
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [trackPageView, location]);

  useEffect(() => {
    const cleanup = trackEnd2EndLatency();
    return () => {
      if (cleanup) cleanup();
    };
  }, [trackEnd2EndLatency]);
  return (
    <div className={classes.wrapper}>
      <UserRoleFilter onFilter={handleFilter}>
        <MasterFramework
          actions={actions}
          routes={routes}
          menus={menus}
          options={options}
          title="COSMAN"
        />
      </UserRoleFilter>
    </div>
  );
};

AppPure.propTypes = {
  classes: PropTypes.object.isRequired,
};

const wrap = _.compose(
  (Component) => withDialogContext(Component),
  (Component) => withDefaultStyles(Component, getStyles),
  (Component) => withFavoriteContext(Component),
  (Component) => withPreferenceContext(Component),
  (Component) => withAccessControlContext(Component),
);

export default wrap(AppPure);
