/* eslint-disable react-hooks/exhaustive-deps */

import { v4 } from 'uuid';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@cosman/authorize';
import { instrumentation } from '@cosman/instrumentation';

const getAlias = (email) => {
  const matches = /^(.+)@(.+)$/.exec(email);
  const [, alias] = matches || [];

  return alias || '';
};

export const useTracker = () => {
  const { activeAccount } = useAuth();
  const location = useLocation();

  const trackingInfo = useMemo(() => {
    return {
      trackId: v4(),
      alias: getAlias(activeAccount.username),
      email: activeAccount.username,
      displayName: activeAccount.name,
      path: location.pathname,
      url: location.pathname + location.search,
      params: location.search,
      timestamp: new Date().toUTCString(),
    };
  }, [location.pathname, location.search, activeAccount.name, activeAccount.username]);

  const trackHttpRequest = useCallback(({
    api,
    state,
  }) => {
    const { trackId, alias, email, displayName, path } = trackingInfo;
    instrumentation.logInfo('httpRequest', {
      requestId: v4(),
      trackId,
      alias,
      email,
      displayName,
      path,
      api,
      state,
      timestamp: new Date().toUTCString(),
    });
  }, [trackingInfo]);

  const trackPageView = useCallback(() => {
    instrumentation.logInfo('pageView', trackingInfo);
  }, [trackingInfo]);

  const trackEvent = useCallback((name) => {
    instrumentation.trackEvent(name, {
      ...trackingInfo,
    });
  }, [trackingInfo]);

  const trackEnd2EndLatency = useCallback(() => {
    const recordUserInteractive = () => {
      instrumentation.trackEvent('trackEnd2EndLatency-interactive', {
        trackId: trackingInfo.trackId,
        path: location.pathname,
        url: location.pathname + location.search,
        params: location.search,
        timeTicks: performance.timeOrigin + performance.now(),
      });
    };
    if ('PerformanceObserver' in window) {
      const observerResource = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lastEntry = entries[entries.length - 1];
        if (lastEntry && lastEntry.initiatorType !== 'css' && lastEntry.name !== 'https://dc.services.visualstudio.com/v2/track') {
          instrumentation.trackEvent('trackEnd2EndLatency-backend', {
            trackId: trackingInfo.trackId,
            path: location.pathname,
            url: location.pathname + location.search,
            params: location.search,
            name: lastEntry.name,
            duration: lastEntry.duration,
            transferSize: lastEntry.transferSize,
            endTimeTicks: performance.timeOrigin + lastEntry.responseEnd,
            startTimeTicks: performance.timeOrigin + lastEntry.startTime,
            responseTime: lastEntry.responseEnd - lastEntry.responseStart,
            responseStatus: lastEntry.responseStatus,
          });
        }
      });
      const observerNavigation = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lastEntry = entries[entries.length - 1];
        if (lastEntry) {
          instrumentation.trackEvent('trackEnd2EndLatency-frontend', {
            trackId: trackingInfo.trackId,
            path: location.pathname,
            url: location.pathname + location.search,
            params: location.search,
            name: lastEntry.name,
            duration: lastEntry.duration,
            transferSize: lastEntry.transferSize,
            resourceTime: lastEntry.responseEnd - lastEntry.startTime,
            processingTime: lastEntry.domComplete - lastEntry.responseEnd,
            loadTime: lastEntry.loadEventEnd - lastEntry.domComplete,
            startTimeTicks: performance.timeOrigin + lastEntry.startTime,
            endTimeTicks: performance.timeOrigin + lastEntry.loadEventEnd,
            responseStatus: lastEntry.responseStatus,
            type: lastEntry.type,
          });
        }
      });
      observerResource.observe({ type: 'resource', buffered: true });
      observerNavigation.observe({ type: 'navigation', buffered: true });

      window.addEventListener('click', recordUserInteractive, { once: true });
      return () => {
        observerResource.disconnect();
        observerNavigation.disconnect();
        window.removeEventListener('click', recordUserInteractive);
      };
    }
    return () => {};
  }, [trackingInfo.trackId]);

  return {
    trackingInfo,
    trackEnd2EndLatency,
    trackHttpRequest,
    trackPageView,
    trackEvent,
  };
};
